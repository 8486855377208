import { useRef } from "react";
import { useIsVisible } from "../hooks/useIsVisible";
import { useCounter } from "../hooks/useCounter";

const ExperinceSection = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const mobiledivRef = useRef<HTMLDivElement>(null);
  const isVisible = useIsVisible(divRef);
  const isMobileVisible = useIsVisible(mobiledivRef);

  const height = useCounter({ isVisible, maxNumber: 400 });

  const responsibilities = [
    "Building stable and maintainable codebase using React native",
    "Creative new feature, functionality, and capability on the apps",
    "Performance bug fixes and code reviews.",
    "Creating reusable UI components using React native with JavaScript.",
    "Apps upload to the Google play store & AppStore",
  ];

  const responsibilitiesII = [
    "Work with complex projects",
    "Building stable and maintainable code base using Typescript & Javascript.",
    "Experince in Jest unit test.",
    "Experience in Jenkins & SonarQube.",
    "Performance bug fixes and code reviews.",
  ];

  return (
    <div
      ref={divRef}
      id="experince"
      className="h-[600px] flex bg-experience-background overflow-hidden max-md:h-[860px]"
    >
      <div>
        <div
          className={`transition-opacity ease-linear duration-1000 ${
            isVisible ? "opacity-1" : "opacity-0"
          }`}
        >
          <p className="text-white text-2xl mt-10 ml-10 max-md:text-xl">
            Experince
          </p>
          <p className="text-white text-6xl font-bold ml-10 max-md:text-3xl">
            My Experince
          </p>
        </div>

        {/* MOBILE SECTION START */}
        <div className="hidden max-md:flex flex-col pr-6 ml-3 w-screen mt-8">
          <div
            className={`w-screen mt-5 hover:scale-125 ease-linear duration-200 hover:translate-x-5 ${
              isVisible ? "animate-slidLeft" : "opacity-0"
            }`}
          >
            <p className="text-lg font-bold text-white mb-3">
              REACT NATIVE DEVELOPER | MOBILE DEVELOPER
            </p>
            <p className="text-sm text-orange100 mb-3">
              ONIMTA INFORMATION TECHNOLOGY (PVT) LTD | MAHARAGAMA
            </p>
            <p className="text-sm font-bold text-grey">NOV 2019 - MAR 2022</p>
          </div>
          <div
            className={`w-full bg-white mt-5 flex-wrap items-center py-3.5 mr-5 ${
              isVisible ? "animate-slidRight" : "opacity-0"
            }`}
          >
            {responsibilities.map((e, i) => (
              <div className="flex mx-2">
                <div className="w-2 h-2 rounded-full bg-primary-background-color mt-1.5 mx-2" />
                <p className="text-sm">{e}</p>
              </div>
            ))}
          </div>

          <div
            ref={mobiledivRef}
            className={`w-screen mt-10 hover:scale-125 ease-linear duration-200 hover:translate-x-14 ${
              isMobileVisible ? "animate-slidLeft" : "opacity-0"
            }`}
          >
            <p className="text-lg font-bold text-white mb-3">
              SOFTWARE ENGINEER II
            </p>
            <p className="text-sm text-orange100 mb-3">ARIMAC | COLOMBO 03</p>
            <p className="text-sm font-bold text-grey">MAR 2022 - PRESENT</p>
          </div>
          <div
            className={`w-full bg-white mt-5 flex-wrap items-center py-3.5 mr-5 ${
              isMobileVisible ? "animate-slidRight" : "opacity-0"
            }`}
          >
            {responsibilitiesII.map((e, i) => (
              <div className="flex mx-2">
                <div className="w-2 h-2 rounded-full bg-primary-background-color mt-1.5 mx-2" />
                <p className="text-sm">{e}</p>
              </div>
            ))}
          </div>
        </div>
         {/* MOBILE SECTION END */}

        <div className="max-md:hidden flex justify-evenly w-screen mt-8 max-lg:mx-5">
          <div
            className={`flex-col max-lg:mr-5 ${
              isVisible ? "animate-slidRight" : "opacity-0"
            }`}
          >
            <div
              className={`w-[450px] h-[150px] bg-white mt-5 flex-wrap items-center py-3.5 max-lg:w-[380px] max-lg:h-[190px] hover:scale-125 ease-linear duration-200`}
            >
              {responsibilities.map((e, i) => (
                <div className="flex mx-2">
                  <div className="w-2 h-2 rounded-full bg-primary-background-color mt-1.5 mx-2" />
                  <p className="text-sm">{e}</p>
                </div>
              ))}
            </div>

            <div className="w-[450px] h-[150px] mt-14 max-lg:w-[380px] hover:scale-125 ease-linear duration-200 hover:-translate-x-14">
              <p className="text-lg font-bold text-white mb-3 text-right">
                SOFTWARE ENGINEER II
              </p>
              <p className="text-sm text-orange100 mb-3 text-right">
                ARIMAC | COLOMBO 03
              </p>
              <p className="text-sm font-bold text-grey text-right">
                MAR 2022 - PRESENT
              </p>
            </div>
          </div>

          <div
            className="w-0.5 bg-white transition-all ease-linear duration-700"
            style={{ height: height }}
          >
            <div
              className={`w-[50px] h-[50px] rounded-full bg-white -ml-6 mt-16 items-center justify-center flex  ${
                isVisible ? "animate-wiggle" : "opacity-0"
              }`}
            >
              <p className="text-black text-xl font-bold">01</p>
            </div>

            <div
              className={`w-[50px] h-[50px] rounded-full bg-primary-background-color -ml-6 mt-36 items-center justify-center flex border-white border-2 delay-700 transition-opacity ease-linear duration-500 ${
                isVisible ? "opacity-1" : "opacity-0"
              }`}
            >
              <p className="text-black text-xl font-bold text-white">02</p>
            </div>
          </div>

          <div
            className={`flex-col  max-lg:ml-5 ${
              isVisible ? "animate-slidLeft" : "opacity-0"
            }`}
          >
            <div className="w-[400px] h-[150px] mt-5 max-lg:h-[190px] hover:scale-125 ease-linear duration-200 hover:translate-x-14">
              <p className="text-lg font-bold text-white mb-3">
                REACT NATIVE DEVELOPER | MOBILE DEVELOPER
              </p>
              <p className="text-sm text-orange100 mb-3">
                ONIMTA INFORMATION TECHNOLOGY (PVT) LTD | MAHARAGAMA
              </p>
              <p className="text-sm font-bold text-grey">NOV 2019 - MAR 2022</p>
            </div>

            <div className="w-[400px] h-[150px] bg-white mt-10 flex-wrap items-center py-3.5 max-lg:w-[350px] max-lg:h-[150px] hover:scale-125 ease-linear duration-200">
              {responsibilitiesII.map((e, i) => (
                <div className="flex mx-2">
                  <div className="w-2 h-2 rounded-full bg-primary-background-color mt-1.5 mx-2" />
                  <p className="text-sm">{e}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExperinceSection;
