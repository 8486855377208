import { ReactNode, createContext, useEffect, useState } from "react";

type ContextType = {
  scrollY: number;
};

export const AppContext = createContext<ContextType>({ scrollY: 0 });

type AppProviderProps = {
  children: ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  const [scrollY, setScrollY] = useState<number>(0);

  useEffect(() => {
    window.addEventListener("scroll", onHandleScroll, { passive: true });
    return () => window.removeEventListener("scroll", onHandleScroll);
  }, []);

  const onHandleScroll = () => {
    setScrollY(window.scrollY);
  };
  return (
    <AppContext.Provider value={{ scrollY }}>{children}</AppContext.Provider>
  );
};
