const SkillsSlider = () => {
  const skillList = [
    "android",
    "react",
    "typescript",
    "redux",
    "css",
    "flutter",
    "node",
    "express",
    "mongo",
    "mysql",
    "firebase",
    "tailwind",
  ];

  return (
    <div
      x-data="{}"
      x-init="$nextTick(() => {
        let ul = $refs.logos;
        ul.insertAdjacentHTML('afterend', ul.outerHTML);
        ul.nextSibling.setAttribute('aria-hidden', 'true');
    })"
      className={`w-full flex flex-nowrap overflow-hidden py-10 bg-primary-background-color`}
    >
      <ul
        x-ref="logos"
        className="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-[80px] animate-infinite-slidLeft"
      >
        {skillList.map((e: string, i: number) => (
          <li key={i}>
            <img
              src={require(`../assets/${e}.png`)}
              alt={e}
              className="object-contain"
            />
          </li>
        ))}
      </ul>
      <ul
        x-ref="logos"
        className="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-[80px] animate-infinite-slidLeft"
      >
        {skillList.map((e: string, i: number) => (
          <li key={i}>
            <img
              src={require(`../assets/${e}.png`)}
              alt={e}
              className="object-contain"
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SkillsSlider;
