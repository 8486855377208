import { useEffect, useState } from "react";

type WindowDimensionsType = {
  width: number;
  height: number;
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] =
    useState<WindowDimensionsType>(getWindowDimensions());

  useEffect(() => {
    window.addEventListener("resize", onHandleScreenResize);
    return () => window.removeEventListener("resize", onHandleScreenResize);
  }, []);

  const onHandleScreenResize = () => {
    setWindowDimensions(getWindowDimensions());
  };

  return windowDimensions;
};
