import { RefObject, useEffect, useState } from "react";

export const useIsVisible = (ref: RefObject<HTMLElement>) => {
  const [isIntersecting, setIntersecting] = useState<boolean>(false);

  useEffect(() => {
    if (!ref.current) return;
    const observe = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting);
    });
    observe.observe(ref.current);
    return () => {
      observe.disconnect();
    };
  }, [ref]);

  return isIntersecting;
};
