import { FC } from "react";
import { IoCloseSharp } from "react-icons/io5";

interface SideMenuProps {
  onPress: () => void;
}

const SideMenu: FC<SideMenuProps> = ({ onPress }) => {
  return (
    <div className="w-screen h-screen bg-[rgba(49,49,80,0.9)]">
      <div className="w-full flex justify-end p-8 cursor-pointer">
        <div onClick={onPress}>
          <IoCloseSharp color="#fff" size={20} />
        </div>
      </div>
      <div className="w-full h-full flex flex-col item-center text-center justify-center -mt-20">
        <a
          href="#home"
          className="text-2xl text-white mb-10 font-semibold"
          onClick={onPress}
        >
          Home
        </a>
        <a
          href="#project"
          className="text-2xl text-white mb-10 font-semibold"
          onClick={onPress}
        >
          Projects
        </a>
        <a
          href="#experince"
          className="text-2xl text-white mb-10 font-semibold"
          onClick={onPress}
        >
          Skills & Experience
        </a>
        <a
          href="#aboutme"
          className="text-2xl text-white font-semibold"
          onClick={onPress}
        >
          About Me
        </a>
      </div>
    </div>
  );
};

export default SideMenu;
