import React, { useEffect, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import SideMenu from "./SideMenu";

const Navbar = () => {
  const [colorChange, setColorChange] = useState<boolean>(false);
  const [isMenuVisible, setMenuVisible] = useState<boolean>(false);

  const changeColor = () => {
    if (window.scrollY >= 80) {
      setColorChange(true);
    } else {
      setColorChange(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeColor);

    return () => {
      window.removeEventListener("scroll", changeColor);
    };
  }, []);

  const textColor = `${colorChange ? "text-[#000]" : "text-[#ffff]"}`;

  return (
    <div
      className={`flex w-full items-center pl-[120px] ${
        colorChange ? " pt-[10px]" : " pt-[40px]"
      } duration-500 fixed z-50  ${
        colorChange ? "bg-white" : "bg-transparent"
      } justify-between max-md:px-10 max-lg:px-10`}
    >
      <div
        className={`absolute w-screen h-screen left-0 top-0 ease-linear duration-300 hidden max-md:flex max-lg:flex`}
        style={{ transform: `translateX(${isMenuVisible ? "0px" : "-950px"})` }}
      >
        <SideMenu onPress={() => setMenuVisible(false)} />
      </div>

      <img
        src={require("../assets/Logo.png")}
        alt="logo"
        className={`${
          colorChange ? "h-[80px]" : "h-[100px]"
        } ease-linear duration-500 animate-wiggle max-md:h-[50px] flex max-md:${
          colorChange ? "pb-1" : "pb-0"
        } max-lg:${colorChange ? "pb-1" : "pb-0"}`}
      />

      <div
        onClick={() => setMenuVisible(!isMenuVisible)}
        className={`hidden cursor-pointer w-[50px] h-[50px] items-center justify-center max-md:flex max-lg:flex max-md:${
          colorChange ? "pb-1" : "pb-0"
        } max-lg:${colorChange ? "pb-1" : "pb-0"} ${
          isMenuVisible ? "opacity-0" : "opacity-1"
        } ease-linear duration-300 animate-wiggle`}
      >
        <GiHamburgerMenu color={colorChange ? "#000" : "#fff"} size={20} />
      </div>

      <div className="max-lg:hidden flex-1 mr-[220px]">
        <ul className="flex justify-center whitespace-nowrap">
          <li
            className={`px-[10px] cursor-pointer ${textColor} hover:text-orange100 active:border-b-2 ease-linear duration-500 animate-slidBottom`}
          >
            <a href="#home">Home</a>
          </li>
          <a
            href="#project"
            className={`px-[10px] ${textColor} cursor-pointer hover:text-orange100 active:border-b-2 ease-linear duration-500 animate-slidBottom`}
          >
            Projects
          </a>
          <li
            className={`px-[10px] ${textColor} cursor-pointer hover:text-orange100 active:border-b-2 ease-linear duration-500 animate-slidBottom`}
          >
            <a href="#experince"> Skills & Experience</a>
          </li>
          <li
            className={`px-[10px] ${textColor} cursor-pointer hover:text-orange100 active:border-b-2 ease-linear duration-500 animate-slidBottom`}
          >
            <a href="#aboutme">About Me</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
