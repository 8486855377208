import { useRef } from "react";
import Slider from "../components/Slider";
import { useIsVisible } from "../hooks/useIsVisible";

const ProjectScreenSection = () => {
  const projectList = [
    {
      title: "Ocean Fairy",
      description:
        "As a client request this web page offers a wide range of homemade customized key tags, birthday cards, and anniversary gifts that are sure to make a lasting impression. Whether you want to surprise a loved one or add a personal touch to your own belongings, our products are designed with love and attention to detail.",
      img: require("../assets/oceanfairy.png"),
      icon: "",
      shadowColor: "rgba(209,209,209,0.5)",
      appUrl: "",
      github: "",
    },
    {
      title: "Stunning Stitches",
      description:
        "Introducing our note snippet app that allows you to effortlessly jot down your thoughts and ideas. With the option to use it as a personal notebook, you can easily organize and access your notes anytime, anywhere. Experience limitless possibilities with our user-friendly interface.",
      img: require("../assets/stichesapp.png"),
      icon: require("../assets/stichesicon.png"),
      shadowColor: "rgba(209,209,209,0.5)",
      appUrl:
        "https://play.google.com/store/apps/details?id=com.ozi.snippet&pcampaignid=web_share",
      github: "https://github.com/Osanda-Adithya/Note-Snippet",
    },
    {
      title: "Trendy Romance",
      description:
        "Stay connected with your friends like never before with our chat feature. Whether you want to catch up, share exciting news, or simply have a laugh, our platform provides a seamless and enjoyable chatting experience.",
      img: require("../assets/trendy.png"),
      icon: require("../assets/trendyicon.png"),
      shadowColor: "rgba(255,255,255,0.5)",
      appUrl:
        "https://play.google.com/store/apps/details?id=com.ozi.romanzu&pcampaignid=web_share",
      github: "https://github.com/Osanda-Adithya/Romanzu-Flutter",
    },
  ];

  const divRef = useRef<HTMLDivElement>(null);
  const isVisible = useIsVisible(divRef);

  return (
    <div
      id="project"
      className="flex w-screen h-[600px] bg-gradient-to-br from-primary-background-color to-primary-background-color max-md:h-[800px] max-md:flex-col"
    >
      <div
        className={`absolute ${
          isVisible ? "opacity-1" : "opacity-0"
        } ease-linear duration-1000 max-md:relative`}
        ref={divRef}
      >
        <p className="text-white text-2xl mt-10 ml-10 max-md:text-xl">
          My Work
        </p>
        <p className="text-white text-6xl font-bold ml-10 max-md:text-3xl">
          Recent Project
        </p>
      </div>

      <Slider listItem={projectList} />
    </div>
  );
};

export default ProjectScreenSection;
