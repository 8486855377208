import { FC, useEffect, useRef, useState } from "react";
import { useWindowDimensions } from "../hooks/useWindowDimensions";
import { useIsVisible } from "../hooks/useIsVisible";

type SliderProps = {
  listItem: any[];
};

const Slider: FC<SliderProps> = ({ listItem }) => {
  const elRef = useRef<HTMLDivElement>(null);
  const { width } = useWindowDimensions();
  const [scrollX, setScrollX] = useState<number>(0);
  const isVisible = useIsVisible(elRef);
  const [count, setCount] = useState<number>(0);

  const onHandleScroll = () => {
    setScrollX(elRef.current?.scrollLeft!);
  };

  const sectionVisible = (offSet: number) => {
    if (scrollX / 7 >= offSet) {
      return isVisible;
    }
    return false;
  };

  useEffect(() => {
    const autoPlay = setTimeout(() => {
      if (count === listItem.length - 1) {
        setCount(0);
        elRef.current?.scrollTo(width * count, 0);
      } else {
        elRef.current?.scrollTo(width * count, 0);
        setCount(count + 1);
      }
    }, 10000);
    return () => clearTimeout(autoPlay);
  }, [count, listItem.length, width]);

  return (
    <div
      ref={elRef}
      onScroll={onHandleScroll}
      className="w-full flex overflow-auto no-scrollbar scroll-smooth snap-x snap-mandatory"
    >
      <div className="flex">
        {listItem.map((item, index) => (
          <div
            key={index}
            className="w-screen flex items-center py-10 snap-center max-md:flex-col max-md:py-0"
          >
            <div className="ml-10 mr-5 mt-10 xl:mr-24">
              <div
                className={`flex mb-6 items-center ease-linear duration-500 transition-all ${
                  sectionVisible((width / 7) * index - 100)
                    ? "animate-slidLeft"
                    : "opacity-0"
                }`}
              >
                {item.icon !== "" ? (
                  <img
                    className="h-[80px] max-xl:w-[80px] object-contain pointer-events-none mr-5 max-md:h-[50px] max-md:mr-0"
                    src={item.icon}
                    alt="/"
                  />
                ) : null}
                <h2 className="text-4xl font-bold text-white max-md:text-2xl">
                  {item.title}
                </h2>
              </div>

              <p
                className={`text-lg text-white ease-linear duration-300 transition-all ${
                  sectionVisible((width / 7) * index - 100)
                    ? "animate-slidTop"
                    : "opacity-0"
                } max-md:text-sm`}
              >
                {item.description}
              </p>

              <div className={`flex flex-wrap mt-10`}>
                {item.github !== "" ? (
                  <button
                    onClick={() => (window.location.href = item.github)}
                    className={`w-[120px] h-[50px] bg-white flex items-center rounded-full ease-linear delay-500 duration-200 transition-all ${
                      sectionVisible((width / 7) * index - 100)
                        ? "animate-wiggle"
                        : "opacity-0"
                    }`}
                  >
                    <img
                      src={require("../assets/github.png")}
                      alt="github"
                      className="w-[50px] h-[50px] ml-1"
                    />
                    GitHub
                  </button>
                ) : null}

                {item.appUrl !== "" ? (
                  <a href={item.appUrl}>
                    <button
                      className={`w-[140px] h-[50px] bg-transparent flex items-center rounded-full justify-center mx-5 border-white border-2 delay-700 ease-linear duration-700 transition-all ${
                        sectionVisible((width / 7) * index - 100)
                          ? "animate-wiggle"
                          : "opacity-0"
                      }`}
                    >
                      <img
                        src={require("../assets/playstore.png")}
                        alt="playstore"
                        className="w-[20px] h-[20px] object-contain"
                      />
                      <p className="text-white ml-2 font-bold">PlayStore</p>
                    </button>
                  </a>
                ) : null}
              </div>
            </div>

            <img
              className={`h-[500px] max-xl:w-[450px] object-contain pointer-events-none drop-shadow-[-50px_0px_90px_rgba(209,209,209,0.5)] ease-linear duration-500 ${
                sectionVisible((width / 7) * index - 100)
                  ? "animate-wiggle"
                  : "opacity-0"
              } max-md:h-[380px] max-md:ml-2`}
              src={item.img}
              alt="/"
            />
          </div>
        ))}
        <div
          className="absolute h-5 mt-[500px] flex max-md:mt-[650px]"
          style={{ marginLeft: width / 2 - 10 }}
        >
          {listItem.map((_, i) => (
            <div
              onClick={() => {
                elRef.current?.scrollTo(width * i, 0);
              }}
              className={`w-[10px] h-[10px] ${
                Math.floor(scrollX / width) === i ? "bg-orange100" : "bg-white"
              } rounded-full mx-1 cursor-pointer ${
                isVisible ? "animate-wiggle" : "opacity-0"
              }`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Slider;
