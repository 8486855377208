import { useContext } from "react";
import { AppContext } from "../context/AppContext";

const EndIntroSection = () => {
  const { scrollY: scrollPosition } = useContext(AppContext);

  const isMobile = window.innerWidth <= 768;

  return (
    <div
      className={`w-screen h-[600px] overflow-hidden ${
        scrollPosition > 650
          ? "bg-purpleBlue100"
          : "bg-primary-background-color"
      } ease-linear duration-500`}
    >
      <div className="absolute flex w-screen h-[600px] bg-[rgba(6,13,39,0.4)]" />

      <div className="absolute flex w-screen h-[600px] justify-end z-10 whitespace-nowrap">
        <h1
          className={`text-white text-7xl font-bold w-2/5 mt-[200px] max-xl:ml-[430px] max-xl:w-3/5
          ${
            scrollPosition > (isMobile ? 3424 : 2683)
              ? "opacity-1"
              : "opacity-0"
          } 
          ease-linear duration-500 max-md:text-3xl max-md:w-3/5 max-md:pr-5`}
          style={{
            transform: `translateY(${
              scrollPosition <= (isMobile ? 4503 : 3503)
                ? scrollPosition / (isMobile ? 4 : 3) - 950
                : 250
            }px)`,
          }}
        >
          Keep in touch
        </h1>
      </div>

      <div className="absolute flex w-screen h-[600px] z-10">
        <img
          src={require("../assets/intobottom.png")}
          alt="introbottom"
          className="w-screen h-[600px] object-cover"
        />
      </div>

      <div
        className={`relative w-screen h-[600px] transition-transform ease-linear duration-500`}
        style={{
          transform: `translateX(${
            Math.floor(scrollPosition / (isMobile ? 4 : 3)) - 900
          }px)`,
          transition: "ease-in",
        }}
      >
        <img
          src={require("../assets/intobackground.png")}
          alt="introbottom"
          className="w-screen h-[600px] object-cover"
        />
      </div>
    </div>
  );
};

export default EndIntroSection;
