import SkillsSlider from "./components/SkillsSlider";
import Navbar from "./components/navbar";
import { AppProvider } from "./context/AppContext";
import AboutMeSection from "./screen/AboutMeSection";
import IntroSection from "./screen/IntroSection";
import ExperinceSection from "./screen/ExperinceSection";
import ContactUsSection from "./screen/ContactUsSection";
import EndIntroSection from "./screen/EndIntroSection";
import HomeScreenSection from "./screen/HomeScreenSection";
import ProjectScreenSection from "./screen/ProjectScreenSection";
import FooterSection from "./screen/FooterSection";
// import { Suspense } from "react";
// import Loading from "./components/Loading";

// const Navbar = lazy(() => import("./components/navbar"));

function App() {
  return (
    // <Suspense fallback={<Loading />}>
      <AppProvider>
        <Navbar />
        <HomeScreenSection />
        <IntroSection title={"Let's begin"} />
        <AboutMeSection />
        <SkillsSlider />
        <ExperinceSection />
        <ProjectScreenSection />
        <EndIntroSection />
        <ContactUsSection />
        <FooterSection />
      </AppProvider>
    // </Suspense>
  );
}

export default App;
