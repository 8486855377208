import { FC, useRef } from "react";
import { useIsVisible } from "../hooks/useIsVisible";
import { useCounter } from "../hooks/useCounter";

const AboutMeSection: FC = () => {
  const refView = useRef<HTMLImageElement>(null);
  const aboutRef = useRef<HTMLHeadingElement>(null);
  const greetingRef = useRef<HTMLHeadingElement>(null);

  const isVisible = useIsVisible(refView);
  const isAboutVisible = useIsVisible(aboutRef);
  const isGreetingVisible = useIsVisible(greetingRef);

  const years = useCounter({ isVisible, maxNumber: 4 });

  return (
    <div
      id="aboutme"
      className="flex flex-row w-screen h-[600px] bg-primary-background-color overflow-hidden max-lg:h-[970px] max-lg:flex-col max-md:flex-col max-md:h-[900px]"
    >
      <div className="columns-auto w-screen h-screen m-10 whitespace-nowrap">
        <h2
          ref={greetingRef}
          className={`text-white text-2xl ${
            isGreetingVisible ? "opacity-1" : "opacity-0"
          } transition-opacity ease-linear duration-1000 max-md:text-xl`}
        >
          Nice to meet you!
        </h2>
        <h1
          className={`text-white text-6xl font-bold ${
            isGreetingVisible ? "opacity-1" : "opacity-0"
          } transition-opacity ease-linear duration-1000 max-md:text-3xl`}
        >
          Welcome To...
        </h1>
        <div className="flex max-lg:justify-center max-lg:mr-20">
          <img
            ref={refView}
            src={require("../assets/Group 5.png")}
            alt="aboutImage"
            className={`object-contain w-[389px] h-[389px] ml-10 mt-10 ${
              isVisible ? "animate-slidLeft" : "opacity-0"
            } transition-transform ease-linear max-md:ml-0 max-md:w-[289px]`}
          />
        </div>
        <div
          className={`absolute flex w-[430px] h-[200px] justify-end items-end ml-16 ${
            isVisible ? "delay-500 opacity-1 animate-wiggle" : "opacity-0"
          }  transition-all ease-linear duration-300 max-lg:ml-64 max-md:-ml-14 max-md:w-screen group hover:scale-125 z-30 cursor-pointer`}
          style={{ marginTop: -500 }}
        >
          <div className="w-[40%] h-[30%] bg-white rounded-lg flex justify-evenly items-center max-md:w-[30%]">
            <img
              src={require("../assets/hand.png")}
              alt="hand"
              className="w-10 h-10 animate-wave max-md:w-7 max-md:h-7 group-hover:animate-infinity-wave"
            />
            <div className="col-auto">
              <p className="text-[#A39E9E] text-sm">Hellow, I'm</p>
              <p className="font-bold">Osanda</p>
            </div>
          </div>
        </div>
      </div>

      <div className="max-xl:w-9/12 mr-10 max-lg:item-center max-lg:w-full">
        <div className="flex-wrap mt-20 max-lg:mt-0 max-md:-mt-[53px]">
          <h1
            ref={aboutRef}
            className={`max-xl:text-7xl text-8xl font-extrabold text-purpleBlue100 text-opacity-30 text-center ${
              isAboutVisible ? "animate-slidRight" : "opacity-0"
            } max-md:text-5xl`}
          >
            ABOUT ME
          </h1>
          <div className="absolute flex mt-[-65px] justify-end w-[580px] max-xl:w-[300px] max-xl:mt-[-55px] max-lg:self-center max-lg:justify-center max-lg:w-full">
            <h1
              className={`text-3xl text-white font-bold ${
                isAboutVisible ? "animate-slidLeft" : "opacity-0"
              } max-md:text-xl max-md:mt-4`}
            >
              Know me more
            </h1>
          </div>
        </div>
        <div className="mt-10 flex-wrap mr-10 ml-32 max-xl:ml-0 max-lg:mx-20 max-lg:mb-10 max-md:mx-10">
          <p
            className={`text-white text-lg font-light transition-opacity ease-linear duration-1000 ${
              isVisible ? "opacity-1" : "opacity-0 "
            } max-md:text-sm`}
          >
            As an experienced developer with over four years of expertise, I
            specialize in craftiing scalable and performant applications
            utilizing cutting-edge technologies such as JavaScript, TypeScript,
            React, and React-native. My passion for web and mobile development
            drives me to seek a challenging and rewarding career that offers
            opportunities for continuous training and advancement. I am
            results-oriented and hardworking, with a keen enthusiasm for
            embracing new responsibilities. I am eager to bring my skills to a
            dynamic team where I can contribute to innovative projects and
            collaborate with like- minded professionals.
          </p>
        </div>

        <div
          className={`flex justify-evenly mt-16 max-xl:mt-5 hover:animate-none ${
            isVisible ? "animate-slidTop" : "opacity-0"
          }`}
        >
          <div className="flex w-[100px] items-center mr-10 -ml-10">
            <h1 className="text-6xl text-orange100 font-bold max-xl:text-4xl">
              {years}+
            </h1>
            <h1 className="text-2xl text-white font-bold ml-4 max-xl:text-lg">
              Years experience
            </h1>
          </div>
          <div className="flex w-[100px] items-center">
            <h1 className="text-6xl text-orange100 font-bold max-xl:text-4xl">
              6
            </h1>
            <h1 className="text-2xl text-white font-bold ml-4 max-xl:text-lg">
              Projects Involved
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMeSection;
