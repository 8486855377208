import { useState, useRef, useEffect } from "react";

type CounterProps = {
  isVisible: boolean;
  maxNumber: number;
};

export const useCounter = (props: CounterProps) => {
  const [count, setCount] = useState<number>(0);
  const heightRef = useRef(count);
  const maxCount = props.maxNumber;
  const countHeight = maxCount / 200;

  useEffect(() => {
    const increaseHeight = () => {
      const result = Math.ceil(heightRef.current + countHeight);
      if (result > maxCount) return setCount(maxCount);
      setCount(result);
      heightRef.current = result;
      setTimeout(increaseHeight, 1000);
    };

    if (props.isVisible) {
      increaseHeight();
    }
  }, [props.isVisible, count, countHeight, maxCount]);

  return count;
};
