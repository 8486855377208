import { useEffect, useRef, useState } from "react";
import { FaGithub, FaLinkedinIn } from "react-icons/fa6";
import { useIsVisible } from "../hooks/useIsVisible";

const HomeScreenSection = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const isVisible = useIsVisible(divRef);
  const [visidility, setVisibility] = useState<boolean>(false);
  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    if (!visidility) {
      setVisibility(isVisible);
    }
  }, [isVisible, visidility]);

  return (
    <div ref={divRef} id="home" className="h-screen snap-center">
      <div className="absolute flex w-screen h-screen bg-[rgba(0,0,0,0.4)]" />
      <div className="absolute justify-end flex w-screen h-screen items-end">
        <img
          alt="profileimage"
          src={require("../assets/Osanda.png")}
          className={`object-contain flex-none min-xl:flex-grow ease-linear duration-1000 transition-opacity ${
            visidility ? "opacity-1" : "opacity-0"
          }`}
        />
      </div>
      <div className="absolute flex flex-col h-screen justify-center mx-20 max-md:mx-8">
        <div
          className={`flex-row flex mt-10 ease-linear duration-500 ${
            visidility ? "animate-slidBottom" : "opacity-0"
          } max-md:-mt-28 max-sm:-mt-36`}
        >
          <h2 className="text-2xl text-white max-md:text-xl">Hi,</h2>
          <h2 className="text-2xl text-orange100 ml-3 font-bold max-md:text-xl">
            I'm Osanda Adithya
          </h2>
        </div>
        <div className="">
          <h1
            className={`text-white text-6xl font-bold w-4/5 xl:w-2/5 ease-linear duration-500 ${
              visidility ? "animate-slidLeft" : "opacity-0"
            } max-md:text-3xl max-md:w-full`}
          >
            Web developer and Mobile developer
          </h1>
          <p
            className={`text-white text-1xl mt-10 w-1/2 ease-linear duration-500 ${
              visidility ? "animate-slidTop" : "opacity-0"
            }  max-md:w-full max-md:text-sm`}
          >
            As a versatile Full Stack Developer, I bring a passion for crafting
            seamless digital experiences across both web and mobile platforms.
            With a solid foundation in front-end and back-end technologies, I
            specialize in developing dynamic and user-centric solutions that
            bridge the gap between functionality and aesthetics.
          </p>

          <div className="absolute mt-10 z-20 flex max-md:flex-col">
            <a href="https://drive.google.com/file/d/1i2gIyD7gThO_OkS9sCdNFMIJBiMnDCE9/view?usp=sharing">
              <button
                className={`bg-orange100 px-5 py-3 mr-5 rounded-full cursor-pointer text-white font-semibold hover:bg-white hover:text-black ease-linear duration-500 ${
                  visidility ? "animate-wiggle" : "opacity-0"
                } max-md:mb-3`}
              >
                Download My CV
              </button>
            </a>
            <a href="#contactus">
              <button
                className={`bg-[transparent] border-2 border-orange100 px-9 py-3 rounded-full cursor-pointer text-orange100 font-semibold hover:border-white hover:text-white ease-linear duration-500 ${
                  visidility ? "animate-wiggle" : "opacity-0"
                }`}
              >
                Hire Me
              </button>
            </a>
          </div>
        </div>
      </div>

      <div
        className="absolute right-3 z-30"
        style={{ bottom: isMobile ? 80 : window.innerHeight / 2 }}
      >
        <a href="https://github.com/Osanda-Adithya">
          <div
            className={`bg-transparent border-2 border-white rounded-full w-[40px] h-[40px] items-center justify-center flex mb-3 cursor-pointer ease-linear duration-500 ${
              visidility ? "animate-wiggle" : "opacity-0"
            }`}
          >
            <FaGithub color="#fff" size={30} />
          </div>
        </a>
        <a href="https://www.linkedin.com/in/osanda-adithya-69490119a">
          <div
            className={`bg-transparent border-2 border-white rounded-full w-[40px] h-[40px] items-center justify-center flex cursor-pointer ease-linear duration-500 ${
              visidility ? "animate-wiggle" : "opacity-0"
            }`}
          >
            <FaLinkedinIn color="#fff" size={20} />
          </div>
        </a>
      </div>

      <div
        className={`absolute z-20 bottom-0 left-1/2  mb-10 ease-linear duration-500 ${
          isVisible ? "animate-bounce" : "opacity-0"
        } max-md:mb-0`}
      >
        <a href="#intro">
          <img src={require("../assets/down-arrow.png")} alt="down-arrow" />
        </a>
      </div>
      <video
        src={require("../assets/homescreenIntro.mp4")}
        autoPlay
        loop
        muted
        playsInline
        disablePictureInPicture
        className="w-full h-screen object-cover"
        onContextMenu={(e) => e.preventDefault()}
      />
    </div>
  );
};

export default HomeScreenSection;
