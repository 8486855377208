import { useEffect, useRef, useState } from "react";
import { useIsVisible } from "../hooks/useIsVisible";
import emailjs from "@emailjs/browser";

interface InputType {
  name: String;
  email: String;
  text: String;
}

const ContactUsSection = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const isVisible = useIsVisible(divRef);
  const formRef = useRef<HTMLFormElement>(null);
  const [isDisable, setDisable] = useState<boolean>(true);
  const [data, setData] = useState<InputType>({
    email: "",
    name: "",
    text: "",
  });

  useEffect(() => {
    isValid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const sendEmail = (e: { preventDefault: () => void }) => {
    setDisable(true);
    e.preventDefault();
    if (formRef.current)
      emailjs
        .sendForm(
          "service_5bw77i7",
          "template_tfe66ec",
          formRef.current,
          "hC1asIe2qVjaXFYTa"
        )
        .then(
          (result) => {
            formRef.current?.reset();
            setDisable(true);
          },
          (error) => {
            formRef.current?.reset();
            setDisable(false);
          }
        );
  };

  const isValid = () => {
    if (
      data.email.trim() !== "" &&
      data.name.trim() !== "" &&
      data.text.trim() !== ""
    ) {
      // eslint-disable-next-line no-useless-escape
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
      setDisable(!reg.test(data.email.toString()));
    } else {
      setDisable(true);
    }
  };

  return (
    <div
      ref={divRef}
      id="contactus"
      className="h-[600px] bg-gradient-to-br from-primary-background-color to-purpleBlue100 flex overflow-hidden max-md:flex-col max-md:h-[1050px]"
    >
      <div>
        <p
          className={`text-white text-2xl mt-10 ml-10 ${
            isVisible ? "opacity-1" : "opacity-0"
          } ease-linear duration-1000 max-md:text-xl`}
        >
          Contact
        </p>
        <p
          className={`text-white text-6xl font-bold ml-10 max-lg:text-4xl max-xl:text-4xl ${
            isVisible ? "opacity-1" : "opacity-0"
          } ease-linear duration-1000 max-md:text-3xl`}
        >
          Reach Out Me
        </p>

        <div
          className={`mt-10 ml-10 ${
            isVisible ? "animate-slidTop" : "opacity-0"
          } ease-linear duration-100 max-md:ml-0`}
        >
          <p className="text-white font-bold text-2xl max-md:text-xl max-md:text-center">
            Address
          </p>
          <p className="text-grey text-lg max-md:text-sm max-md:text-center">
            337/A, Thanthirimulla, Panadura
          </p>
          <p className="text-grey text-lg max-md:text-sm max-md:text-center">
            Sri Lanka
          </p>
        </div>

        <div
          className={`mt-10 ml-10 ${
            isVisible ? "animate-slidTop" : "opacity-0"
          } ease-linear duration-100 max-md:mt-5 max-md:ml-0`}
        >
          <p className="text-white font-bold text-2xl max-md:text-xl max-md:text-center">
            Email Address
          </p>
          <p className="text-grey text-lg max-md:text-sm max-md:text-center">
            osandaadithya@gmail.com
          </p>
        </div>

        <div
          className={`mt-10 ml-10 ${
            isVisible ? "animate-slidTop" : "opacity-0"
          } ease-linear max-md:mt-5 max-md:ml-0`}
        >
          <p className="text-white font-bold text-2xl max-md:text-xl max-md:text-center">
            Mobile Number
          </p>
          <p className="text-grey text-lg max-md:text-sm max-md:text-center">
            +94758785112
          </p>
        </div>
      </div>

      <div className="w-2/3 ml-10 mr-5 mt-10 max-md:w-full max-md:ml-5">
        <div
          className={`w-full flex justify-end ${
            isVisible ? "animate-slidRight" : "opacity-0"
          }`}
        >
          <p className="text-white font-bold text-4xl w-2/4 text-end mr-10 max-lg:text-2xl max-xl:text-3xl max-md:w-full max-md:mr-10 max-md:text-center">
            Got Ideas? We have got the skills. Lets team up.
          </p>
        </div>

        <form ref={formRef}>
          <div
            className={`flex mt-8 ${
              isVisible ? "opacity-1" : "opacity-0"
            } ease-linear duration-1000 max-md:flex-col`}
          >
            <div className="w-1/2 mx-5 max-md:w-full max-md:ml-0 max-md:pr-10 max-md:mb-8">
              <p className="text-white text-xl mb-4 max-md:text-lg">
                Full Name
              </p>
              <input
                type="text"
                name="user_name"
                placeholder="Full Name"
                onChange={(e) => setData({ ...data, name: e.target.value })}
                className="bg-purpleBlue100  text-white w-full h-[50px] px-5"
              />
            </div>
            <div className="w-1/2 mx-5 max-md:w-full max-md:ml-0 max-md:pr-10">
              <p className="text-white text-xl mb-4 max-md:text-lg">
                Email address
              </p>
              <input
                type="email"
                name="from_name"
                placeholder="Email Address"
                onChange={(e) => setData({ ...data, email: e.target.value })}
                className="bg-purpleBlue100  text-white w-full h-[50px] px-5"
              />
            </div>
          </div>
          <div
            className={`mx-5 mt-8 ${
              isVisible ? "opacity-1" : "opacity-0"
            } ease-linear duration-1000 max-md:w-full max-md:ml-0 max-md:pr-10`}
          >
            <p className="text-white text-xl mb-4 max-md:text-lg">
              Your message
            </p>
            <textarea
              name="message"
              placeholder="Your Message"
              onChange={(e) => setData({ ...data, text: e.target.value })}
              className="bg-purpleBlue100  text-white w-full h-[150px] p-5"
            />
            <button
              disabled={isDisable}
              className={`${
                isDisable ? "bg-grey" : "bg-orange100"
              } w-full h-[50px] mt-5 active:${
                isDisable ? "bg-grey" : "bg-white"
              }`}
              onClick={isDisable ? undefined : sendEmail}
            >
              <p className="text-lg font-semibold">Send Message</p>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactUsSection;
