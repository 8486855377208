import { FC, useContext } from "react";
import { AppContext } from "../context/AppContext";

type IntroSectionProps = {
  title: string;
};

const IntroSection: FC<IntroSectionProps> = ({ title }) => {
  const { scrollY: scrollPosition } = useContext(AppContext);

  return (
    <div
      id="intro"
      className={`w-screen h-[600px] overflow-hidden ${
        scrollPosition > 650
          ? "bg-purpleBlue100"
          : "bg-primary-background-color"
      } ease-linear duration-500`}
    >
      <div className="absolute flex w-screen h-[600px] bg-[rgba(6,13,39,0.4)]" />

      <div className="absolute flex w-screen h-[600px] justify-end z-10 whitespace-nowrap">
        <h1
          className={`text-white text-7xl font-bold w-2/5 mt-[180px] max-xl:mr-[100px] ${
            scrollPosition > 250 ? "opacity-1" : "opacity-0"
          } ease-linear duration-500 max-md:text-3xl max-md:w-1/5 max-md:pr-24`}
          style={{
            transform: `translateY(${
              scrollPosition <= 1200 ? scrollPosition / 3 - 80 : 300
            }px)`,
          }}
        >
          {title}
        </h1>
      </div>

      <div className="absolute flex w-screen h-[600px] z-10">
        <img
          src={require("../assets/intobottom.png")}
          alt="introbottom"
          className="w-screen h-[600px] object-cover"
        />
      </div>

      <div
        className={`relative w-screen h-[600px] transition-transform`}
        style={{
          transform: `translateX(${scrollPosition / 5 - 100}px)`,
          transition: "ease-in",
        }}
      >
        <img
          src={require("../assets/intobackground.png")}
          alt="introbottom"
          className="w-screen h-[600px] object-cover"
        />
      </div>
    </div>
  );
};

export default IntroSection;
